// import $ from 'jquery'
// import '/_plugins.min'

// jQuery( ($) => {
import 'matchheight/src/main'
// import './_ga'
// import './plugins/jquery.isotope'

// // import './_chart'
// // import './_chart-utils'
// // import './_events-data'
// import 'skrollr'
// // import './_plugins.parallax'
// // import './_plugins.flexslider'
// // import './_functions'
// import './_plugins'
// // import './_plugins.min'

// // import './_jquery-migrate.min'
// // import './_jquery.calendario'
// // import './_jquery.gmap'
// // import './_jquery.hotspot'

// })

