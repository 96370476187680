import 'matchheight'
import './style.scss'
import './js/main'
// import './js/_distributor-locator'
import './sass/_dark.scss'

    // cache container
    var $container = $('#shop');

    function getHashFilter() {
      var hash = location.hash;
      // get filter=filterName
      var matches = location.hash.match( /filter=([^&]+)/i );
      var hashFilter = matches && matches[1];
      return hashFilter && decodeURIComponent( hashFilter );
    }
    console.log(getHashFilter())

    let params = (new URL(document.location)).searchParams;
    let filter = params.get('filter') ? `.filter--${params.get('filter')}` : '*'
    // initialize isotope
    $container.isotope({
    filter,
    sortBy: 'number',
    sortAscending: false 
    });
    

        // filter items when filter link is clicked
    $('.product-filters a').on('click', function(){
      $('.product-filters a.active').removeClass('active')
      var selector = $(this).attr('data-filter');
      $container.isotope({ filter: selector });

      $(this).addClass('active')
      return true;
    });

    var coll = document.getElementsByClassName("collapsible");
    var i;
    
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var content = this.element;
        if (content.style.display === "block") {
          content.style.display = "none";
        } else {
          content.style.display = "block";
        }
      });
    } 